import { TenderModel } from 'app/modules/tendering/models/tender.model';

export const SET_CURRENT_TENDER = '[Current Tendering] Set Current Tender';

export class SetTender {
  static readonly type = `${SET_CURRENT_TENDER} Set Current Tender`;
  constructor(public tender: TenderModel) {}
}

export class ExtendTender {
  static readonly type = '[Tender] Extend Tender';
  constructor(
    public tenderId: string,
    public extendedToDate: string
  ) {}
}
