import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EXTEND_TENDER_MUTATION } from '@shared/gql-shared-queries';
import { Apollo } from 'apollo-angular';
import { TenderModel } from 'app/modules/tendering/models/tender.model';
import { catchError, of, tap } from 'rxjs';
import { ExtendTender, SetTender } from './current-tender.actions';

export interface CurrentTenderStateModel {
  currentTender: TenderModel;
}

const defaults: CurrentTenderStateModel = {
  currentTender: null,
};

@State<CurrentTenderStateModel>({
  name: 'tendering',
  defaults,
})
@Injectable()
export class CurrentTenderState {
  constructor(private apollo: Apollo) {}

  @Selector()
  static getTender(state: CurrentTenderStateModel) {
    return state.currentTender;
  }

  @Selector()
  static getTenderStatus(state: CurrentTenderStateModel) {
    return state.currentTender?.tenderStatus;
  }

  @Action(SetTender)
  setTender({ patchState }: StateContext<CurrentTenderStateModel>, { tender }: SetTender) {
    patchState({ currentTender: tender });
  }

  @Action(ExtendTender)
  extendTender(
    { patchState, getState }: StateContext<CurrentTenderStateModel>,
    { tenderId, extendedToDate }: ExtendTender
  ) {
    return this.apollo
      .mutate({
        mutation: EXTEND_TENDER_MUTATION,
        variables: {
          tenderId,
          extendedToDate,
        },
      })
      .pipe(
        tap(response => {
          const tender = response.data['extendTender'];
          // patchState({ currentTender: { ...getState().currentTender, tenderTerm: tender.tenderTerm } });
        }),
        catchError(err => {
          console.error('Error extending tender', err);
          return of(null);
        })
      );
  }
}
