import { Injectable } from '@angular/core';
import { JobUpdateNotification } from '@core/models/job-updates-notification.model';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { SetTender } from 'app/modules/wizard/store/current-tender/current-tender.actions';
import { Observable, map, tap } from 'rxjs';
import { TenderStates } from '../enums/tender-status.enum';
import { TenderModel } from '../models/tender.model';
import { UpdateTenderStatusAction } from '../store/bids/bids.actions';

@Injectable({ providedIn: 'root' })
export class TenderService {
  constructor(
    private apollo: Apollo,
    private store$: Store
  ) {}

  handleTenderUpdate(tenderNotification: JobUpdateNotification, currentTenderId: string): void {
    if (tenderNotification.entityType !== 'TENDER') {
      return;
    }

    if (currentTenderId === tenderNotification.data?.['_id']) {
      this.store$.dispatch([
        new SetTender(tenderNotification.data as undefined as TenderModel),
        new UpdateTenderStatusAction(tenderNotification.data?.['tenderStatus'] as TenderStates),
      ]);
    }
  }

  loadTenderById(tenderId: string) {
    const GET_TENDER_BY_ID = gql`
      query findTenderById($tenderId: String!) {
        findTenderById(tenderId: $tenderId) {
          _id
          tenderRefNumber
          tenderStatus
          tenderTerm {
            data
          }
          tenderCommittee {
            committeeLead {
              _id
            }
            committeeReviewers {
              _id
            }
            financialExperts {
              _id
            }
            logisticsExperts {
              _id
            }
          }
        }
      }
    `;

    return this.apollo
      .query<TenderModel>({
        query: GET_TENDER_BY_ID,
        variables: { tenderId },
      })
      .pipe(
        map(result => result.data['findTenderById']),
        tap(tender => {
          this.store$.dispatch([new SetTender(tender), new UpdateTenderStatusAction(tender.tenderStatus)]);
        })
      );
  }

  evaluateTender(tenderId: string): Observable<boolean> {
    return this.updateTenderStatus(tenderId, TenderStates.EVALUATING).pipe(
      tap(() => this.loadTenderById(tenderId).subscribe()),
      map(result => result)
    );
  }

  terminateTender(tenderId: string): Observable<boolean> {
    return this.updateTenderStatus(tenderId, TenderStates.TERMINATED);
  }

  updateTenderStatus(tenderId: string, tenderStatus: TenderStates): Observable<boolean> {
    const updateTenderStatusMutation = gql`
      mutation updateTenderStatus($tenderId: String!, $tenderStatus: String!) {
        updateTenderStatus(tenderId: $tenderId, tenderStatus: $tenderStatus)
      }
    `;
    return this.apollo
      .mutate<boolean>({
        mutation: updateTenderStatusMutation,
        variables: { tenderId, tenderStatus: tenderStatus },
      })
      .pipe(map(response => response.data['updateTenderStatus']));
  }

  markAsFavorite(bidId: string, bidOptionId: string) {
    const markAsFavoriteMutation = gql`
      mutation markAsFavorite($bidId: String!, $bidOptionId: String!) {
        markAsFavorite(bidId: $bidId, bidOptionId: $bidOptionId)
      }
    `;
  }
}
